import React from "react"
import { Link } from "gatsby"
import { Navbar, NavbarToggler, Collapse, NavbarNav, Nav, Form, FormControl, Button } from "react-bootstrap"
import { container } from '../styles/style.scss'

const CustomNavbar = () => {

    // toggleCollapse = collapseID => () =>
    // this.setState(prevState => ({
    //   collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    // }))


    return (
        <div className={container}>
            <Navbar expand="lg" id="site-navbar">
            {/* <Container> */}
            
                <Navbar.Toggle aria-controls="basic-navbar-nav" right/>
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/#about">About</Nav.Link>
                    <Nav.Link href="/#artists">Our Artists</Nav.Link>
                    <Nav.Link href="/#faq">FAQ</Nav.Link>
                    <Nav.Link href="/#contact">Contact Us</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            {/* </Container> */}
            </Navbar>
            {/* <Navbar className="navbar navbar-expand-lg navbar-light bg-light" id="site-navbar">
            
                    <Button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </Button>
                <Link to="/" className="link-no-style">
                    <Navbar.Brand as="span">Home</Navbar.Brand>
                </Link>
            </Navbar> */}
        </div>
    )
}

export default CustomNavbar