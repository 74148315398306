import React, {useState} from "react"
import { Button, Modal, Snippet } from "react-bootstrap"
import { container } from "../styles/style.scss"
import ClipboardCopy from "../components/clipboardCopy";


const PaymentModal = ({
    rate,
    setText,
    address,
    setAddress,
    setShow,
    show,
    copyText
}) => {
    
    const [isCopied, setIsCopied] = useState(false)

    return (
        <div className={container}>
            <Modal
                show={show}
                onClose={() => {
                    setShow(false)
                }}
                style={{
                    fontFamily: 'Montserrat',
                }}
            >
                    <Modal.Header>
                        <Modal.Title>Purchase Artist Drop NFT Here</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="text"
                            value={`${rate.usdRate ? rate.usdRate : "..."}`}
                        />
                        <p>USD Rate</p>
                          <input
                            type="text"
                            value={`${address.adaToSend ? address.adaToSend : "..."}`}
                        />
                        <p>Send This much $ADA</p>
                        <ClipboardCopy 
                            
                            copyText={`${address.paymentAddress ? address.paymentAddress : "..."}`} 
                        />
                        <p>To your preferred wallet of choice</p>
                  

                    </Modal.Body>
                    <Modal.Footer>
                        <p>It is vital that you never buy using any exchange including but not limited to Binance, Coinbase, or Kucoin. Reccomendation are to send using Nami Wallet, CC Wallet, Yoroi, Daedalus or AdaLite.  Once the exact ADA is sent, you will receive your unique NFT in a few minutes.</p>
                        <Button onClick={() => {
                            setShow(false)
                        }}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PaymentModal