import PropTypes from "prop-types"
import React from "react"
import headerGIF from '../images/artist-drop.gif'
import background from '../images/artist-drop-background.png'

const Header = () => {
    return (
        <header           
            style={{
                backgroundImage: `url(${background})`,
                backgroundSize: "cover",
                paddingBottom: `1.0875rem`,
        
            }}    
        >     
        <img src={headerGIF} class="img-fluid" alt="Responsive image" />
            <h1 className="img-fluid"
                style={{        
                paddingBottom: `1.0875rem`,
                paddingLeft: `1.0875rem`,     
                fontFamily: 'Bungee', 
                fontSize: `3rem`,
                color: `#FFB324`
                }}
                >
                    Empowering
                    Musicians
            </h1>
            {/* <button
                style={{ 
                margin: `0 auto`,
                marginLeft: `1.0875rem`,
                textAlign: "center",
                fontFamily: 'Montserrat',
                color: 'white',
                border: '3px solid orange',
                backgroundColor: '#48CF89'
                    
                }}
            >
                Learn More
            </button>
            <button
                style={{ 
                margin: `0 auto`,
                marginLeft: `2.0875rem`,
                textAlign: "center",
                backgroundColor: 'transparent',
                fontFamily: 'Montserrat',
                color: '#FFB324',
                border: '3px solid #FFB324'

                }}
            >
                Purchase
            </button> */}
        </header>
    )
}

export default Header
