import React, {useState} from "react"


function ClipboardCopy({ copyText }) {

    const [isCopied, setIsCopied] = useState(false)

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          return document.execCommand('copy', true, text);
        }
      }
    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClip
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                console.log("clicked!")
                alert('The Address has been Copied!')
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div>
          <input type="text" style={{
            width: `20rem`,
            height: `3rem`
            }} 
            value={copyText} readOnly />
            <br/>
          {/* Bind our handler function to the onClick button property */}
          <button style={{
              borderRadius: `5rem`,
              fontFamily: 'Bungee',
              marginLeft: 'auto',
              marginRight: 'auto', 
              backgroundColor: '#48CF89'
            }} onClick={handleCopyClick}>
            <span>{isCopied ? 'Copied!' : 'Copy Address To Clipboard'}</span>
          </button>
        </div>
      );
}

export default ClipboardCopy;
